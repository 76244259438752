 
 // Переменные содержат стили установки и отображения табов 
export const stateA = 'tabbar__left active_link';
export const stateB = 'tabbar__left';
export const stateAres = "content active-content";
export const stateBres = "content";
////////




export const chooseDayString = ( dayNum ) => {
    if (dayNum === 0) {
        return 'Sun';
    } else if (dayNum === 1) {
        return 'Mon';
    } else if (dayNum === 2) {
        return 'Tue';
    } else if (dayNum === 3) {
        return 'Wed';
    } else if (dayNum === 4) {
        return 'Thu';
    } else if (dayNum === 5) {
        return 'Fri';
    } else if (dayNum === 6) {
        return 'Sat';
    }
};
export const monthText2 = [ 
    'Сiчня', 'Лютого', 'Березня', 'Квiтня', 'Травня', 'Червня', 
   'Липня', 'Серпня', 'Вересня', 'Жовтня', 'Листопада', 'Грудня'     
    ];
export const monthText = [ 
    'Января', 'Февраля', 'Марта', 'Апреля','Мая', 'Июня',
     'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'    
    ];
export const chooseMonthNum = ( monthString ) => {
    if (monthString === 'Сiчня') {
        return '01';
    } else if (monthString === 'Лютого') {
        return '02';
    } else if (monthString === 'Березня') {
        return '03';
    } else if (monthString === 'Квiтня') {
        return '04';
    } else if (monthString === 'Травня') {
        return '05';
    } else if (monthString === 'Червня') {
        return '06';
    } else if (monthString === 'Липня') {
        return '07';
    } else if (monthString === 'Серпня') {
        return '08';
    } else if (monthString === 'Вересня') {
        return '09';
    } else if (monthString === 'Жовтня') {
        return '10';
    } else if (monthString === 'Листопада') {
        return '11';
    } else if (monthString === 'Грудня') {
        return '12';
    } if (monthString === 'Января') {
        return '01';
    } else if (monthString === 'Февраля') {
        return '02';
    } else if (monthString === 'Марта') {
        return '03';
    } else if (monthString === 'Апреля') {
        return '04';
    } else if (monthString === 'Мая') {
        return '05';
    } else if (monthString === 'Июня') {
        return '06';
    } else if (monthString === 'Июля') {
        return '07';
    } else if (monthString === 'Августа') {
        return '08';
    } else if (monthString === 'Сентября') {
        return '09';
    } else if (monthString === 'Октября') {
        return '10';
    } else if (monthString === 'Ноября') {
        return '11';
    } else if (monthString === 'Декабря') {
        return '12';
    } 
};

export const chooseMonthString = (monthNumber, setMonthString) => {
    if (monthNumber === '') {
        return;
    }    
    if (monthNumber === 1) {
        setMonthString(monthText2[0]);
    }
    if (monthNumber === 2) {
        setMonthString(monthText2[1]);
    }
    if (monthNumber === 3) {
        setMonthString(monthText2[2]);
    }
    if (monthNumber === 4) {
        setMonthString(monthText2[3]);
    }
    if (monthNumber === 5) {
        setMonthString(monthText2[4]);
    }
    if (monthNumber === 6) {
        setMonthString(monthText2[5]);
    }
    if (monthNumber === 7) {
        setMonthString(monthText2[6]);
    }
    if (monthNumber === 8) {
        setMonthString(monthText2[7]);
    }
    if (monthNumber === 9) {
        setMonthString(monthText2[8]);
    }
    if (monthNumber === 10) {
        setMonthString(monthText2[9]);
    }
    if (monthNumber === 11) {
        setMonthString(monthText2[10]);
    }
    if (monthNumber === 12) {
        setMonthString(monthText2[11]);
    }   
};
 
export const rep = ['', 'ГЛУХОВСЬКИЙ Д.Ю.', 'КОЖУХАРЬ В.А.'];

export const car = [
    {label: '', value: '', id: 1 },
    {label: 'Білий NISSAN LEAF - 4992', value: 4992, id: 2, price: 16000, priceString: '(Шiстнадцять тисяч)' },
    {label: 'Чорний NISSAN LEAF - 1317', value: 1317, id: 3, price: 16000, priceString: '(Шiстнадцять тисяч)' }
];

export const charger = [
    {label: '', value: 'NONE', id: 1, price: 0, priceString: '' },
    {label: 'NISSAN', value: 'NISSAN', id: 2, price: 200, priceString: '(Двісті)'},
    {label: 'DUOSIDA', value: 'DUOSIDA', id: 3, price: 200, priceString: '(Двісті)' },
    {label: 'Без З/У', value: 'NONE', id: 4, price: 0, priceString: '' }
];

export const ridesOld2 = {
    bond: [    
        {_id: '1', service: 'бонд', initialCost: 120, correction: 0, cost: 120, range: 10, time: '12:20', date: '2021-06-05', dateDay: '05', dateMonth: 'Июня', dateYear: 'Дневная абонплата за пользование программой Шарк', percent: 15, serviceCost: 0, correction2: 0, image: '/images/camera.jpg', notes:'Ya' },
        {_id: '2', service: 'бонд', initialCost: 80, correction: 0, cost: 80, range: 6, time: '11:10', date: '2021-06-08', dateDay: '08', dateMonth: 'Июня', dateYear: '2021',percent: 15, serviceCost: 0, image: '/images/camera.jpg', notes: ''},
        {_id: '3', service: 'бонд', initialCost: 150, correction: 0, cost: 150, range: 12, time: '23:40', date: '2021-06-08',dateDay: '08', dateMonth: 'Июня', dateYear: '2021', percent: 15, serviceCost: 0, image: '/images/camera.jpg', notes: ''},
        ],
     uklon: [
        {_id: '10', service: 'uklon', initialCost: 155, correction: 0, cost: 155, range: 16, time: '23:40', date: '2021-06-08', dateDay: '08', dateMonth: 'Июня', dateYear: '2021',percent: 10, serviceCost: 0, image: '/images/camera.jpg', notes: ''},
        {_id: '11', service: 'uklon', initialCost: 220, correction: 0, cost: 220, range: 18, time: '12:40', date: '2021-06-18', dateDay: '18', dateMonth: 'Июня', dateYear: '2021',percent: 10, serviceCost: 0, image: '/images/camera.jpg', notes: ''}
     ],
     tachka: [
        {_id: '12', service: 'тачка', initialCost: 100, correction: 0, cost: 100, range: 12, time: '23:40', date: '2021-06-12', dateDay: '12', dateMonth: 'Июня', dateYear: '2021',percent: 7.5, serviceCost: 0, image: '/images/camera.jpg', notes: ''}
     ],
     shark: [
        {_id: '20', service: 'shark', initialCost: 0, correction: 0, cost: 0, range: 0, time: '00:00', date: '2021-06-14', dateDay: '14', dateMonth: 'Июня', dateYear: '2021', percent: 0, serviceCost: 5, image: '', notes: 10 },
        {_id: '21', service: 'shark', initialCost: 42, correction: 25, cost: 67, range: 2.2, time: '14:16', date: '2021-06-14', dateDay: '14', dateMonth: 'Июня', dateYear: '2021', percent: 14, serviceCost: 0, image: '/images/camera.jpg', notes: ''},
     ]
};

export const rides = [
       
        {_id: '1', service: 'бонд', initialCost: 120, correction: 0, cost: 120, range: 10, sort:'2021-06-0512:20',  time: '12:20', date: '2021-06-05', dateDay: '05', dateMonth: 'Июня', dateYear: '2021', percent: 15, serviceCost: 0, image: '/images/camera.jpg', notes: ''},
        {_id: '2', service: 'бонд', initialCost: 80, correction: 0, cost: 80, range: 6, sort: '', time: '11:10', date: '2021-06-08', dateDay: '08', dateMonth: 'Июня', dateYear: '2021',percent: 15, serviceCost: 0, image: '/images/camera.jpg', notes: ''},
        {_id: '3', service: 'бонд', initialCost: 150, correction: 0, cost: 150, range: 12, sort: '', time: '23:40', date: '2021-06-08',dateDay: '08', dateMonth: 'Июня', dateYear: '2021', percent: 15, serviceCost: 0, image: '/images/camera.jpg', notes: ''},
       
    ];

    export const fetchAPI = 'https://sn-menageit-default-rtdb.europe-west1.firebasedatabase.app/rides.json';


export const balnceFrame = [
    {index: '12', monthNum: '12', monthString: 'Грудень', monthStringUkr: 'Грудня', monthStringRu: 'Декабря'},
    {index: '11', monthNum: '11', monthString: 'Листопад', monthStringUkr: 'Листопада', monthStringRu: 'Ноября'},
    {index: '10', monthNum: '10', monthString: 'Жовтень', monthStringUkr: 'Жовтня', monthStringRu: 'Октября'},
    {index: '9', monthNum: '09', monthString: 'Вересень', monthStringUkr: 'Вересня', monthStringRu: 'Сентября'},
    {index: '8', monthNum: '08', monthString: 'Серпень', monthStringUkr: 'Серпня', monthStringRu: 'Августа'},
    {index: '7', monthNum: '07', monthString: 'Липень', monthStringUkr: 'Липня', monthStringRu: 'Июля'},
    {index: '6', monthNum: '06', monthString: 'Червень', monthStringUkr: 'Червня', monthStringRu: 'Июня'},
    {index: '5', monthNum: '05', monthString: 'Травень', monthStringUkr: 'Травня', monthStringRu: 'Мая'},
    {index: '4', monthNum: '04', monthString: 'Квітень', monthStringUkr: 'Квiтня', monthStringRu: 'Апреля'},
    {index: '3', monthNum: '03', monthString: 'Березень', monthStringUkr: 'Березня', monthStringRu: 'Марта'},
    {index: '2', monthNum: '02', monthString: 'Лютий', monthStringUkr: 'Лютого', monthStringRu: 'Февраля'},
    {index: '1', monthNum: '01', monthString: 'Січень', monthStringUkr: 'Сiчня', monthStringRu: 'Января'}        
];
 
export const test = [
   
    {
      car: 2104, sum: 8000, restSum:2000, months: 60, perMonth: 133.3, start:'valueShiftEndDate', startYear: 2022, startMonth: '01', startYearMonth: 202201,fin:'valueShiftEndDate',finishYearMonth: 202701, paidSum: 1466.3,
      paidOn: [

       {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '01', 
        paidYearMonth: 202201,
        monthString: 'Січень',
        sum: 1
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '02', 
        paidYearMonth: 202202,
        monthString: 'Лютий',
        sum: 2
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '03', 
        paidYearMonth: 202203,
        monthString: 'Березень',
        sum: 3
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '04', 
        paidYearMonth: 202204,
        monthString: 'Квітень',
        sum: 4
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '06', 
        paidYearMonth: 202206,
        monthString: 'Травень',
        sum: 6
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '05', 
        paidYearMonth: 202205,
        monthString: 'Травень',
        sum: 5
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '08', 
        paidYearMonth: 202208,
        monthString: 'Травень',
        sum: 8
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '07', 
        paidYearMonth: 202207,
        monthString: 'Травень',
        sum: 7
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '09', 
        paidYearMonth: 202209,
        monthString: 'Травень',
        sum: 9
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '10', 
        paidYearMonth: 202210,
        monthString: 'Травень',
        sum: 10
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '11', 
        paidYearMonth: 202211,
        monthString: 'Травень',
        sum: 11
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '12', 
        paidYearMonth: 202212,
        monthString: 'Травень',
        sum: 12
      },
    ]
    },

    {
      car: 1236, sum: 7000, restSum:2000, months: 60, perMonth: 140, start:'valueShiftEndDate', startYear: 2022, startMonth: '01', startYearMonth: 202201,fin:'valueShiftEndDate', finishYearMonth: 202701, paidSum: 1500,
      paidOn: [
       {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '01', 
        paidYearMonth: 202201,
        monthString: 'Січень',
        sum: 1
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '02', 
        paidYearMonth: 202202,
        monthString: 'Лютий',
        sum: 2
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '03', 
        paidYearMonth: 202203,
        monthString: 'Березень',
        sum: 3
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '04', 
        paidYearMonth: 202204,
        monthString: 'Квітень',
        sum: 4
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '05', 
        paidYearMonth: 202205,
        monthString: 'Травень',
        sum: 5
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '06', 
        paidYearMonth: 202206,
        monthString: 'Травень',
        sum: 6
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '07', 
        paidYearMonth: 202207,
        monthString: 'Травень',
        sum: 7
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '08', 
        paidYearMonth: 202208,
        monthString: 'Травень',
        sum: 8
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '10', 
        paidYearMonth: 202210,
        monthString: 'Травень',
        sum: 10
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '09', 
        paidYearMonth: 202209,
        monthString: 'Травень',
        sum: 9
      },  
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '12', 
        paidYearMonth: 202212,
        monthString: 'Грудень',
        sum: 12
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2022, 
        paidMonth: '11', 
        paidYearMonth: 202211,
        monthString: 'Листопад',
        sum: 11
      },
      {
        dateNum:'valueShiftEndDate',
        paidYear: 2021, 
        paidMonth: '05', 
        paidYearMonth: 202105,
        monthString: 'Травень',
        sum: 5
      },      
    ]
    },

    {
        car: 2136, sum: 9000, restSum:2000, months: 60, perMonth: 150, start:'valueShiftEndDate', startYear: 2022, startMonth: '01', startYearMonth: 202201,fin:'valueShiftEndDate', finishYearMonth: 202701, paidSum: 1500,
        paidOn: [
         {
          dateNum:'valueShiftEndDate',
          paidYear: 2022, 
          paidMonth: '01', 
          paidYearMonth: 202201,
          monthString: 'Січень',
          sum: 150
        },
        {
          dateNum:'valueShiftEndDate',
          paidYear: 2022, 
          paidMonth: '02', 
          paidYearMonth: 202202,
          monthString: 'Лютий',
          sum: 150
        },
        {
          dateNum:'valueShiftEndDate',
          paidYear: 2022, 
          paidMonth: '03', 
          paidYearMonth: 202203,
          monthString: 'Березень',
          sum: 0
        },
        {
          dateNum:'valueShiftEndDate',
          paidYear: 2022, 
          paidMonth: '04', 
          paidYearMonth: 202204,
          monthString: 'Квітень',
          sum: 150
        },
        {
          dateNum:'valueShiftEndDate',
          paidYear: 2022, 
          paidMonth: '05', 
          paidYearMonth: 202205,
          monthString: 'Травень',
          sum: 150
        },
        {
          dateNum:'valueShiftEndDate',
          paidYear: 2022, 
          paidMonth: '06', 
          paidYearMonth: 202206,
          monthString: '',
          sum: 150
        },
        {
          dateNum:'valueShiftEndDate',
          paidYear: 2022, 
          paidMonth: '07', 
          paidYearMonth: 202207,
          monthString: '',
          sum: 150
        },
        {
          dateNum:'valueShiftEndDate',
          paidYear: 2022, 
          paidMonth: '08', 
          paidYearMonth: 202208,
          monthString: 'Травень',
          sum: 150
        },
        {
          dateNum:'valueShiftEndDate',
          paidYear: 2022, 
          paidMonth: '09', 
          paidYearMonth: 202209,
          monthString: 'Травень',
          sum: 150
        },
        {
          dateNum:'valueShiftEndDate',
          paidYear: 2022, 
          paidMonth: '10', 
          paidYearMonth: 202210,
          monthString: 'Травень',
          sum: 150
        },        
      ]
      }
      
  ];
  export const yearMonths = [
      {month: 'Січень'}, {month: 'Лютий'}, {month: 'Березень'}, {month: 'Квітень'}, {month: 'Травень'}, {month: 'Червень'}, 
      {month: 'Липень'}, {month: 'Серпень'}, {month: 'Вересень'}, {month: 'Жовтень'}, {month: 'Листопад'}, {month: 'Грудень'}
      ];
  export const yearMonthsObj = {
    month1: 'Січень', month2: 'Лютий', month3: 'Березень', month4: 'Квітень', month5: 'Травень', month6: 'Червень', 
    month7: 'Липень', month8: 'Серпень', month9: 'Вересень', month10: 'Жовтень', month11: 'Листопад', month12: 'Грудень',
  };

  export const family = [
    {
     name:'Denys',
     tel: '+380960188881'
    }, 
    {
     name:'Iryna',
     tel: '+380674747442'
    },
    {
    name:'Yaroslav',
    tel: '+380969942737'
    }, 
    {
    name:'Yeva',
    tel: '+380689434159'
    }, 
    {
    name:'Dasha',
    tel: '+380686118177'
    }, 
]
export const locations = [
                      {
                        place: 'General_Info',
                        dateIn: '30-08-23',
                        dateOut: '13-09-23',
                        timeIn: '16:00',
                        timeOut: '23:59'
                      },
                       {
                        place: 'Edinbourgh',
                        dateIn: '30-08-23',
                        dateOut: '03-09-23',
                        timeIn: '23:55',
                        timeOut: '08:30'
                       },
                       {
                        place: 'Invernes',
                        dateIn: '03-09-23',
                        dateOut: '04-09-23',
                        timeIn: '12:00',
                        timeOut: '08:30'
                       },
                       {
                        place: 'Highlands',
                        dateIn: '04-09-23',
                        dateOut: '06-09-23',
                        timeIn: '10:00',
                        timeOut: '13:00'
                       },
                       {
                        place: 'IOM',
                        dateIn: '06-09-23',
                        dateOut: '09-09-23',
                        timeIn: '14:05',
                        timeOut: '12:20'
                       },
                       {
                        place: 'London',
                        dateIn: '09-09-23',
                        dateOut: '13-09-23',
                        timeIn: '14:00',
                        timeOut: '16:40'
                       },
                          ]

    // const data = [1, 2, 3, 4, 8]
    // const a = data[data.length - 1];
    // const b = data[data.length - 2];
    // const lastTwo = +data[data.length-1] - +data[data.length-2];
