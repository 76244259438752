import React,  { useContext, useEffect } from 'react';
import { ContractContext } from "../Context/ContractContext";
//import { CollectionsContext } from "../Context/CollectionsContext";
import Dashboard from './Dashboard';
import c from '../Components/Screen.module.css';
import useFirestore from "../Hooks/useFirestore";
import useV3Firestore from "../Hooks/useV3Firestore";

const DashboardData = () => {

//const { rentFleetCollection, privatFleetCollection } = useContext(CollectionsContext);
//const {privatCars} = useFirestore(privatFleetCollection);
//const {fleetCars} = useV3Firestore(rentFleetCollection);

const carsCollection = 'fleet_cars';
const privatCarsCollection = 'privat_cars';
const {fleetCars} = useV3Firestore(carsCollection);
const {privatCars} = useFirestore(privatCarsCollection);
//console.log(fleetCars);

const {
    carData, 
    setCarData, 
    privatCarData, 
    setPrivatCarData
   } = useContext(ContractContext);

useEffect(() => {
    if (fleetCars.length > 0) {
     setCarData(fleetCars);
    }   
    // eslint-disable-next-line
}, [fleetCars]);

useEffect(() => {
    if(privatCars.length > 0) {
        setPrivatCarData(privatCars);    
    }
    // eslint-disable-next-line
}, [privatCars]);

    return (
        <div className={`${c.container} ${c.home}`}>          
            <Dashboard 
             data={carData}
             privatCarData={privatCarData}         
            />                    
        </div>
    )
}

export default DashboardData

