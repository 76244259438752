import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import { Container, Modal, FormControl } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { useHistory } from "react-router-dom";
import AddEditBtnFleet from '../Components/AddEditBtnFleet';
import FooterButtons from '../Components/FooterButtons';
//import { addItemWithTrigers, delItemNoRef } from '../Functions';
import AlertOnAction from '../Components/AlertOnAction';
import { editItemNoRef, addItemNoRef, editItemWithTrigers } from '../Functions';
import useGetCommonItems from '../Hooks/useGetCommonItems';
import { useAuth } from "../Context/AuthContext";
//import { RidesContext } from '../Context/RidesContext';
import useV3Firestore from '../Hooks/useV3Firestore';



const AddNewPayment = (props) => {

  
 

const car = localStorage.getItem('car');
const carID = localStorage.getItem('carID'); 
const carsCollection = 'fleet_cars';

//const seconds = Date.now();
const { userName } = useAuth();

//////////////////// Блок запису даних
const [addingItem, setAddingItem] = useState(false);
const [doneAdding, setDoneAdding] = useState(false);
const [error, setError] = useState(false);
const [trigger, setTrigger] = useState(false);
const [carRunData, setCarRunData] = useState({});
const [controllItemValue, setControllItemValue] = useState('');

//// 1. Отримуємо перший запис з колекції Загальних прибутків
const {commonIncomsList} = useGetCommonItems(props.commonCollection, trigger, 1);
///// 2. Записуємо данні 1го запису Загал.кол. в змінну controllItemValue
useEffect(() => {
 if(commonIncomsList.length === 1) { 
  setControllItemValue(commonIncomsList[0].valueShiftEndDate);  
 } 
},[commonIncomsList]);
///// 3. Отримуе данні нового запису з Ф-ї addNewSpend
const [addingData, setAddingData] = useState({});
const [editingData, setEditingData] = useState({});
// eslint-disable-next-line 
const [commonID, setCommonID] = useState({});
///// 4. Записуємо нові данні в Загал.кол.
const addItemToCommonCollection = (data) => {
  setAddingData(data);
  setEditingData(data);
  addItemNoRef(data, props.commonCollection);
  setTrigger(!trigger);  
};
//////// 5. Oчикуємо отримання оновденних данних Загал.кол. 
/////// та записуємо commonID в новий документ
useEffect(() => {
  if(controllItemValue !== '') { 
   if(addingData !== {}) {   
    if(controllItemValue === addingData.valueShiftEndDate) {
        
      setCommonID(commonIncomsList[0].id);
      let data = {
        ...addingData,
        commonItemID : commonIncomsList[0].id
      }; 
/////// 6. Додамо новий запис в інд. коллекцію     
       addItemNoRef(data, props.collection);
       editItemNoRef(carRunData, carsCollection, carID); /// Оновлю       
      
       setAddingData({}); // Обнуляє переменну AddingData. Запобігає спрацьовиванню строки 50.
       //dataAdded();
//Видаляє дубль запис
       //delItemNoRef(props.collection, props.docID);
      // goBackToPrevPage();      
    }
   }   
  } 
  // eslint-disable-next-line
 },[controllItemValue, addingData]);
/// 7. Перезапишим valueShiftEndDate в обидвох колекціях

//const { RentPayment } = useContext(RidesContext);
const { incoms } = useV3Firestore(props.collection, 1);
//console.log(1, incoms, props.noData);
useEffect(() => {
  if(props.noData) {
    return
  } else if(incoms.length > 0) {
    //console.log(10, incoms);
      if(editingData.valueShiftEndDate === incoms[0].valueShiftEndDate) {
          let dataToEdit = {
          valueShiftEndDate: +dateNum,
          indCollection: props.collection,
          indID: incoms[0].id
          };
         // console.log(2, dataToEdit, );
          editItemNoRef(dataToEdit, 'commonIncoms', incoms[0].commonItemID);
          editItemWithTrigers(dataToEdit, props.collection, incoms[0].id, setAddingItem, setDoneAdding, setError);
       } 
  }
// eslint-disable-next-line
}, [incoms, editingData]);
/////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const editItem = (data) => {  
    let dataToEdit = {            
      sum: +data.sum,
      // dateDay: data.dateDay, 
      // dateMonth: data.dateMonth, 
      // dateYear: +data.dateYear,
     // sort: +data.sortDate,  
     // image: data.image,    
      note: data.note,
      userName, 
      run: enteredTime,
     // valueShiftEndDate: +dateNum    
   };
   //console.log(props.data.commonItemID);
    editItemNoRef(dataToEdit, 'commonIncoms', props.data.commonItemID);
    console.log('edit 1 passed');
    editItemWithTrigers(dataToEdit, props.collection, props.data.id, setAddingItem, setDoneAdding, setError);        
    goBackToPrevPage();
};
/////////////////////////////////////////////////////////////////////////////////////////////////
 
/////////////////////////////////////// Проверка заполнения формы
const [timeEmpty, setTimeEmpty] = useState(false);
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
////////////////////////////////////////////////////////////////////////
const [showUrl, setShowUrl] = useState('');
const [showModalForm, setShowModalForm] = useState(false);
///////////////////////////// Объявляем переменные
const [sum, setSum] = useState('');
const [enteredTime, setEnteredTime] = useState('');
const [note, setNote] = useState('');
const [image, setImage] = useState('');
//////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState('');  
  const [sortDate, setSortDate] = useState('');
  const [dateDayNextPay, setDateDayNextPay] = useState('');
  const [dateMonthNextPay, setDateMonthNextPay] = useState('');
  const [dateYearNextPay, setDateYearNextPay] = useState(''); 
  const [dateNum, setDateNum] = useState('');
  const [nextPayData, setNextPayData] = useState(''); 
  const [sortDateNextPay, setSortDateNextPay] = useState('');
  const [dateEmptyNextPay, setDateEmptyNextPay] = useState(false);
///////////////////////////////////////////////////////////
useEffect(() => {
  if(props.data && showModalForm) {
  setSum(props.data.sum);
  setEnteredTime(props.data.run);
  setNote(props.data.note);
  setImage(props.data.image);
  setDateNum(props.data.valueShiftEndDate);
  }
}, [props.data, showModalForm]);

/////////////////////////// Продолжаем собирать и устанавливать данные формы
const chooseEnteredTime = (event) => {
  setEnteredTime(event.target.value);
  setTimeEmpty(false); 
  setNotice(false);     
};

const chooseSum = (event) => {
  setSum(event.target.value); 
  setSumEmpty(false); 
  setNotice(false);    
};
///////////////////////////////// Добавим Алерт о добавлении Траты
const [alertSuccess, setAlertSuccess] = useState(false);
const history = useHistory();
function goBackToPrevPage () {
history.push(`/rent_payments`);
}
function dataAdded() {
   setAlertSuccess(true);
   setTimeout(() => setAlertSuccess(false), 2000 );
   setTimeout(() => setShowModalForm(false), 2300 );        
   setTimeout(() => goBackToPrevPage(), 2350 ); 
   setTimeout(() => setDoneAdding(false), 2500 ); 
       setEnteredTime('');
       setSum('');
       setNote('');
       setImage('');       
       setNotice(false);
       setTimeEmpty(false);
       setDateEmpty(false);
       setSumEmpty(false);
       setShowUrl(false); 
       setDateEmptyNextPay(false);         
}



const checkForError = () => {
  if (addingItem && !doneAdding) {
    setError(true);
    setAddingItem(false);
    setDoneAdding(false);
    setTimeout(() => setError(false), 4000 );
    return;
  } else if (doneAdding) {
    setError(false);
    return;
  }
};
useEffect(() => {
  if (!error && doneAdding) {
       dataAdded();      
  } if (error) {
    setTimeout(() => setError(false), 4000 ); 
    return; 
  } if (addingItem) {
    setTimeout(() => checkForError(), 30000 );
    return;
  }
// eslint-disable-next-line
}, [error, doneAdding, addingItem]);

 ////////// Проверим заполненность формы 
const checkForm = () => {  
    if (sortDate === '') {
      setDateEmpty(true);
      setNotice(true);         
    } if (sortDateNextPay === '') {
      setDateEmptyNextPay(true);
      setNotice(true);
    } if (enteredTime === '') {
      setTimeEmpty(true);
      setNotice(true); 
    } if (sum === '') {
      setSumEmpty(true);
      setNotice(true);
    } if (!notice) {
      addNewSpend();
    } else {return;}
  };
      const checkUpdate = () => {         
         if (enteredTime === '') {          
         setTimeEmpty(true);
         setNotice(true);        
        } if (sum === '') {        
         setSumEmpty(true);
         setNotice(true);            
        } if (!notice) {
           console.log('Check passed. Go to addNewSpend()');   
         addNewSpend();
         
        } else {return;}
    };
const [paidOnDate, setPaidOnDate] = useState('');
//const paidOn = new Date().toLocaleDateString();
const paidOn = paidOnDate;


  function addNewSpend () {
  ///////////////////////////// Формируем данные поездки
        const data = { 
          car: car,
          sum: +sum,        
          run: enteredTime,
          dateDay: dateDay, 
          dateMonth: dateMonth, 
          dateYear: +dateYear, 
          dateDayNextPay: dateDayNextPay, 
          dateMonthNextPay: dateMonthNextPay, 
          dateYearNextPay: +dateYearNextPay,          
          image: image,
          userName: userName,
          note: note,
          sort: +sortDate,
          valueShiftEndDate: 2000000000000,       
        }; 

    console.log(data);
    setAddingData(data);  
  /////////////////////////// Отправлем форму
  let runData =  {
          latestRun : data.run,
          weeklyPaidOn: paidOn,
          nextPayData: nextPayData
        };
  setCarRunData(runData);

  console.log(+dateNum);
  //props.noDate ? editItem(data) : addItemToCommonCollection(data); 

  // if (!props.noDate) {
  //   addItem(data);
  //  console.log(    
  //     {
  //       latestRun : data.run,
  //       weeklyPaidOn: paidOn,
  //       nextPayData: nextPayData
  //     }
  //   );
  //   addItem(data);
  //  //alert('Hi!');
     
  // } else if (props.noDate) {

  //   //////////////////////////
  //   /////////////////////////
  //   // props.addNewPayment(data);
  //   //       addLatestRun(
  //   //    {latestRun : data.run}
  //   //    );
  //   //    dataAdded();
  //   ////////////////////////
  //   ///////////////////////
   
  //   ////// DELETE line below!!!!!!!
  //   //console.log(data, 'Controll Data Sending to execute');
    
  //   addItem(data);

  // }     
}

const fileType = (data) => {
  console.log(data);
}; 
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
  setEnteredTime('');
  setSum('');
  setNote('');
  setImage(''); 
  setShowModalForm(false);
  setNotice(false);
  setDateEmptyNextPay(false); 
  setTimeEmpty(false);
  setDateEmpty(false);
  setSumEmpty(false);
  setShowUrl(false);
  setAddingItem(false);
  setDoneAdding(false);
  setError(false);
};  

//className="bg-dark"

    return (
        <>
        
        <div className={!props.noDate ? `mb-3 ${c.goback__btn__cont}` : 'mb-3'}>
          <AddEditBtnFleet 
          action={() => setShowModalForm(true)}
          style={props.style}
          title={props.title ? props.title : 'Додати запис'}
          noDate={props.noDate}
         />
        </div>

        <Modal
          
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={props.noDate ? (`Редагувати Запис- ${car}`) : (`Додайте запис про оплату - ${car}`)}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
             {alertSuccess && 
                <AlertOnAction 
                    header={'Запис додано'}
                    />
              }
               {!alertSuccess && error ? (
                 <AlertOnAction 
                   variant={'danger'}
                   header={`Збій додавання даних. Перевірте з'єднання`}
                  />                 
               ) : (
           <>
             {!alertSuccess && addingItem ? (
                <AlertOnAction 
                  variant={'info'}
                  header={'Додаю запис в БД'}
                />
                    ) : (
                      <>
                     
                  
                {!props.noDate && 
                 <DatePicker 
                   header={'Дата'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   //setSortDate={setSortDate}
                   setSortDateNum={setSortDate}
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату'}
                   dateEmpty={dateEmpty}

                   paidOnDate={''}
                   paidOnDateNum={setPaidOnDate}
                   dateNum={setDateNum}
                   />
                  } 

                <AlertDangerRound 
                 alertHeader={'Вкажіть пробіг авто'}
                 triger={timeEmpty}
                />
                 <ModalFormline header={'Пробіг по одометру'}>                     
                      <FormControl value={enteredTime} onChange={chooseEnteredTime} type="number"/>                    
                 </ModalFormline>

                 <AlertDangerRound
                  alertHeader={'Вкажіть отриману суму'}
                  triger={sumEmpty}
                  /> 
                  <ModalInputGroup 
                     header={'Отримана Сума'}
                     value={sum} onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    />
                 {!props.noDate &&
                  <DatePicker 
                   header={'Дата наступного платежу'}
                   setDateDay={setDateDayNextPay}
                   setDateMonth={setDateMonthNextPay}
                   setDateYear={setDateYearNextPay}
                   setSortDate={setSortDateNextPay}
                   setDateEmpty={setDateEmptyNextPay}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату наступного платежу'}
                   dateEmpty={dateEmptyNextPay}

                  // payDayTodayNum={setNextPayData}
                   nextPayDayNum={setNextPayData}
                   />
                   }

                   <ModalFormline header={'Примітки'}>                          
                         <FormControl value={note} onChange={(event) => setNote(event.target.value)} type="text" as="textarea" rows={3} />                       
                    </ModalFormline> 
           
                   <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будь ласка, заповнить необхідні дані'}
                     />
                    </>)}                     
                  </>)}
             </Container>
           </Modal.Body>
           <FooterButtons 
            onCancel={hideModal}
            onAccept={!props.noDate ? checkForm : checkUpdate}
            disabled={error || addingItem || loadingImg}
           />      
         </Modal>
        </>
    )
}

export default AddNewPayment
