import React, { useState } from 'react';
import c from "./docs.module.css";
import { Image, Container, Button, Row } from 'react-bootstrap';
import AddDocument from "./AddDocument";
import { X_CircleIcon, TrashIcon, HandIndexThumbIcon } from "../Components/Icons";
import { addItemNoRef, delItemNoRef } from '../Functions';
import { useAuth } from "../Context/AuthContext";
import useGetDocs from '../Hooks/useGetDocs'
import SpinnerBig from '../Components/SpinnerBig';
import NavBarInit from '../Components/NavBarInit';
import { family } from '../Components/Constants';



const Documents = () => {
    
      
const { isAdmin } = useAuth();
const [person, setPerson] = useState(localStorage.getItem('person'))
const collection = `${person}_documents`;

const [triger, setTriger] = useState(false);
const { documentsList, loadingDocs } = useGetDocs(collection, triger)


const addDocument = (data) => {
    addItemNoRef(data, collection);  
    setTriger(!triger);
   };
 
   const deleteImg = (docID) => { 
       if (isAdmin) {
        delItemNoRef(collection, docID);        
        setTriger(!triger);
       } else {return;}     
   };


const link = (data) => {
    localStorage.setItem('person', data);
    setPerson(data)
    setTriger(!triger)
}



    return (
    <>
    <NavBarInit />
        <Container >
            <Container>
              
                  {family.map(p => 
                    <Button 
                        key={p.name}
                        variant='outline-light'
                        className='m-3'
                        onClick={() => link(p.name)}
                    >
                        {p.name}
                    </Button>  
                     )} 
               
            </Container>
       
             <h4>Документи {person}</h4>
             <AddDocument         
              addDocument={addDocument}
             />
  {loadingDocs ? (
    <SpinnerBig />
       ) : (
    <>
        <div className={c.img_grid}>
            {documentsList.length > 0 && documentsList.map((doc) => (
                doc.type === 'application/pdf' ? (
                    <div  key={doc.id}>
                        <a href={doc.image} target='_blank' rel="noreferrer">
                            <div className={c.doc_div } >
                                <h6 className='mt-4 '>
                                    Файл                                  
                                </h6><br/>
                                <h3>PDF</h3> 
                               <h4 >{HandIndexThumbIcon()}</h4>
                               <h6 className='mt-1'>                                    
                                    Click to open
                                </h6>
                               <div  src={doc.image} fluid></div> 
                            </div>
                            <br/>
                            <h6 className='mt-4' >{doc.note}</h6>
                        </a>
                        <div onClick={() => deleteImg(doc.id)}>
                           <span className={c.delete_button} >{X_CircleIcon()}</span>
                        </div>                   
                    </div>                   
                ) : (
                <div  key={doc.id}  >
                    <a href={doc.image} target='_blank' rel="noreferrer">
                        <Image fluid src={doc.image} alt='Document Image' className={c.img_height}/>

                        <br/>
                        <h6 className='mt-4' >{doc.note}</h6>
                    </a>
                  
                    <div onClick={() => deleteImg(doc.id)} variant='danger' className={c.delete_button}>
                         <span className={'c.delete_button'} >{TrashIcon()}</span>
                    </div> 
                </div>)               
            ))}
            
        </div>
    </>)}
        </Container>
    </>
    )
}

export default Documents
