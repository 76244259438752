import React from 'react';
import SelectBox from './SelectBox';



const SelectType = ( {onSelect, type, commonItem} ) => {

const optionsListOne = [
  {value: "chassis", description: "Ходова"},
  {value: "tiers", description: "Колеса"},
  {value: "Г С М", description: "Г М М"},
  {value: "brakes", description: "Гальмівна с-ма"},
  {value: "steering", description: "Рульове кир."},
  {value: "electrics", description: "Електрика"},
  {value: "charger", description: "Зарядний пристрій"},
  {value: "paint", description: "Молярка/Рихтовка"},
  {value: "cabin", description: "Салон"},
  {value: "heater", description: "С-ма Опалення"},
  {value: "other", description: "Іньше"}
];
const optionsListTwo = [
  {value: "salary", description: "Зарплатня"},
  {value: "it", description: "IT"},
  {value: "comms", description: "Зв'язок"},
  {value: "docs", description: "Документо обіг"},
  {value: "insurance", description: "Страховка"},
  {value: "delivery", description: "Доставка"},
  {value: "charger", description: "Зарядний пристрій"},
  {value: "consultancy", description: "Консультаційні посл."},
  {value: "losses", description: "Losses"},
  {value: "chassis", description: "Ходова"},
  {value: "tiers", description: "Колеса"},
  {value: "Г С М", description: "П М М"},
  {value: "brakes", description: "Гальмівна с-ма"},
  {value: "steering", description: "Рульове кир."},
  {value: "electrics", description: "Електрика"},
  {value: "paint", description: "Молярка/Рихтовка"},  
  {value: "body_parts", description: "Кузовні запчастини"},
  {value: "cabin", description: "Салон"},
  {value: "heater", description: "С-ма Опалення"},
  {value: "other", description: "Іньше"}
];


    return (
      <>
        <SelectBox 
          header={'Розділ'} 
          value={type.toUpperCase()}
          onChange={event => onSelect(event)}
          options={commonItem ? optionsListTwo : optionsListOne} 
         />
      </>
    )
}

export default SelectType
