import React, { useContext } from 'react';
import { Container, Nav } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { RidesContext } from '../Context/RidesContext';
import { useAuth } from "../Context/AuthContext";
import  c  from './Screen.module.css';
import {
  personCircleIcon,
  BitcoinIcon,
  ClipboardIcon,
  cashStackIcon,
  DocumentsIcon,
  homeIcon, 
  AirplaneIcon
}  from '../Components/Icons';
import { FaCar, FaRegCalendarAlt } from "react-icons/fa";
import { MdBuildCircle } from "react-icons/md";
import { RiContactsLine } from "react-icons/ri";
//import { GoChecklist } from "react-icons/go";
import { IconContext } from "react-icons";


const NavBarInit = () => {

  
    const { setShifts } = useContext(RidesContext);
   
    const goHome = () => {
      setShifts('');
      sessionStorage.setItem('shift', '');
      sessionStorage.setItem('shiftID', '');
      sessionStorage.setItem('car', '');

      localStorage.setItem('shift', '');
      localStorage.setItem('shiftID', '');
      localStorage.setItem('car', '');
    };
  
  const { userName, isAdmin } = useAuth();

    return (
       <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
         <Container d-flex="md" flex-direction-column="md" justify-content-center="md">
           <Navbar.Brand as={Link} to="/" onClick={() => setShifts('')}>S&N ManageIt</Navbar.Brand>
           <Navbar.Toggle aria-controls="basic-navbar-nav" />
           <Navbar.Collapse id="basic-navbar-nav">
             <Nav className="w-100 me-auto">
             <Nav.Link as={Link} to="/" onClick={goHome}>
             <div className='mb-2 mx-1'>
               {homeIcon()}
             </div>
               
               <span className='mt-2' >ГОЛОВНА</span>
             </Nav.Link>

             

               <Nav.Link as={Link} to="/to_requests">
                 <div className='mb-2 mx-1'>
                   <IconContext.Provider value={{ size: "2.7em" }}>
                         <MdBuildCircle /> <br/>                      
                   </IconContext.Provider> 
                 </div>                  
                  <span className='mt-2 mx-1' >СТО</span>                  
                </Nav.Link>
               <Nav.Link as={Link} to="/payments">
               <div className='mb-2'>
                  <IconContext.Provider value={{ size: "2.7em" }}>
                         <FaRegCalendarAlt /> <br/>                      
                  </IconContext.Provider> 
               </div>                  
                  <span className='mt-2 mx-1'>КАЛЕНДАР</span>  
               </Nav.Link>

               <Nav.Link as={Link} to="/contacts">
               <div className='mb-2 mx-1'>
                  <IconContext.Provider value={{ size: "2.7em" }}>
                         < RiContactsLine /> <br/>                      
                  </IconContext.Provider> 
               </div>                  
                  <span className='mt-2 mx-1' >КОНТАКТИ</span>  
               </Nav.Link>

               {/* <Nav.Link as={Link} to="/todos">
               <div className='mb-2 mx-1 ml-2'>
                  <IconContext.Provider value={{ size: "2.7em" }}>
                         < GoChecklist /> <br/>                      
                  </IconContext.Provider> 
               </div>                  
                  <span className='mt-2 mx-1' >ПОРОБЛЯЙКИ</span>  
               </Nav.Link> */}

               {isAdmin ? 
           (
             <>
             <Nav.Link as={Link} to="/fleet">
               <div className='mb-2 mx-1'>
                  <IconContext.Provider value={{ size: "2.7em" }}>
                         <FaCar /> <br/>                      
                  </IconContext.Provider> 
               </div>                  
                  <span className='mt-2' >Fleet</span>
               </Nav.Link>

               <Nav.Link as={Link} to="/crypto">
                   <div className='mb-2 mx-1'>
                   {BitcoinIcon()}
                   </div>                 
                   <span className='mb-2' >Crypto </span> 
               </Nav.Link>
               <Nav.Link as={Link} to="/taxes">
                   <div className='mb-2 mx-1'>
                   {ClipboardIcon()}
                   </div>                 
                   <span className='mb-2' >Tax</span> 
               </Nav.Link> 
               <Nav.Link as={Link} to="/financial">
                   <div className='mb-2 mx-1'>
                   {cashStackIcon(34)}
                   </div>                 
                   <span className='mb-2' >Фін.Рез</span> 
               </Nav.Link> 
               <Nav.Link as={Link} to="/documents">
                   <div className='mb-2 mx-1'>
                   {DocumentsIcon(34)}
                   </div>                 
                   <span className='mb-2' >Докі</span> 
               </Nav.Link> 
               <Nav.Link as={Link} to="/travel">
                   <div className='mb-2 mx-1'>
                   {AirplaneIcon(34)}
                   </div>                 
                   <span className='mb-2' >Travel</span> 
               </Nav.Link> 
               {/* <Nav.Link as={Link} to="/website">
                   <div className='pt-1 mb-2 mx-1'>
                   {globeIcon(30)}
                   </div>                 
                   <span className='mb-2' >сайт</span> 
               </Nav.Link>               */}
           </>
           ) : ('')
            }  
             </Nav>
           
            
             <Nav className="w-50 me-auto">
              <div className={`mr-5 ${c.car_number_container}`} >
                <div>        
                 <Nav.Link  as={Link} to="/logout">
                  <div className={` mb-3 mb-md-0  ${c.flex_col_centered} ml-5`}>
                    {personCircleIcon()}                
                    <span className='mt-2' >{userName && userName}</span>
                  </div> 
                 </Nav.Link>
                </div>
              </div>
             </Nav>            
             
           </Navbar.Collapse>
         </Container>
       </Navbar>
    )
}

export default NavBarInit
