import React, { createContext, useContext, useState, useEffect }  from 'react';
//import { auth } from "../firebase";
import firebase from 'firebase/app';
import "firebase/auth";

const AuthContext = createContext();

export function useAuth() {   
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {

    const auth = firebase.auth();

    const [currentUser, setCurrentUser] = useState('Гость');
    const [userName, setUserName] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [isTech, setIsTech] = useState(false);
    useEffect(() => {
         
         if (currentUser && currentUser.uid === 'mUCgKTVs00M8yNpXs9tfpuC2dtv2') {
            setUserName('Денис');
            setIsAdmin(true);
            setIsTech(false);
        }
        else if (currentUser && currentUser.uid === 'X7nZuDrZDdc4QebOjw2PmdPoR9j2') {
            setUserName('Олексій');
            setIsAdmin(false);
        } else if (currentUser && currentUser.uid === 'IthbtJDm7YNBpkETtyt8ye58Iss2') {
            setUserName('Віталій');
            setIsAdmin(false);
            setIsTech(true);
         } 
         else if (currentUser && currentUser.uid === '4yBRxsOL2oZoP08Ex6GXo3aLuFA3') {
            setUserName('ttt');
            setIsAdmin(false);
            setIsTech(true);
        } else if (currentUser && currentUser.uid === '9G5omNgx0Tcf54MTIGmboMTbtZu1') {
            setUserName('Микита'); 
            setIsAdmin(false);
        }
        else { 
            setUserName('Гість');
            setIsAdmin(false);
        }
        
    }, [currentUser]);
///////////////////////////////// Переписать формулу тут и дальше
   

/////////////////////////////////////////////
// eslint-disable-next-line
const [loading, setLoading] =useState(true);

function signup(email, password) {
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    return auth.createUserWithEmailAndPassword(email, password);
}

function login(email, password) {
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    return auth.signInWithEmailAndPassword(email, password);
    
}

function logout() {
   return auth.signOut();
}

function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
}

function updateEmail(email) {
    return currentUser.updateEmail(email);
}

function updatePassword(password) {
    return currentUser.updatePassword(password);
}

useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
        setCurrentUser(user);
        setLoading(false);        
});
return unsubscribe; 
// eslint-disable-next-line   
}, []);


const value = {
    currentUser,
    userName,
    signup,
    logout,
    resetPassword,
    updatePassword,
    updateEmail,
    isAdmin,
    isTech,
    login
};

return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
)
}

//{!loading && children} - !loading - Будет останавливать приложение в оффлайн режиме

// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
//         .then(() => {
//             return auth.signInWithEmailAndPassword(email, password);
//         });
