import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import { Container, Modal, FormControl } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { AddBtn } from '../Components/AddBtn';
import SelectType from '../Components/SelectType';
import { addItemNoRef, addItemWithTrigers, editItemNoRef, editItemWithTrigers } from '../Functions';
import AlertOnAction from '../Components/AlertOnAction';
import FooterButtons from '../Components/FooterButtons';

import useV3Firestore from '../Hooks/useV3Firestore';
import useGetCommonSpendings from '../Hooks/useGetCommonSpendings';

const AddNewTO = (props) => {


const car = sessionStorage.getItem('car');
const carID = localStorage.getItem('carID'); 
const collection = `${car}_TO_${props.division}`;
const { userName } = useAuth();

//////////////////// Блок запису даних
const [addingItem, setAddingItem] = useState(false);
const [doneAdding, setDoneAdding] = useState(false);
const [error, setError] = useState(false);
const [trigger, setTrigger] = useState(false);
const [carRunData, setCarRunData] = useState({});
const [controllItemValue, setControllItemValue] = useState('');

const [dateNum, setDateNum] = useState('');
//// 1. Отримуємо перший запис з колекції Загальних прибутків
const { commonSpendingsList } = useGetCommonSpendings(props.commonCollection, trigger, 1);
console.log(commonSpendingsList);
///// 2. Записуємо данні 1го запису Загал.кол. в змінну controllItemValue
useEffect(() => {
 if(commonSpendingsList.length === 1) { 
  setControllItemValue(commonSpendingsList[0].valueShiftEndDate);  
                                   console.log(commonSpendingsList[0].valueShiftEndDate);
 } 
},[commonSpendingsList]);
///// 3. Отримуе данні нового запису з Ф-ї addNewSpend
const [addingData, setAddingData] = useState({});
const [editingData, setEditingData] = useState({});
// eslint-disable-next-line 
const [commonID, setCommonID] = useState({});
///// 4. Записуємо нові данні в Загал.кол.
const addItemToCommonCollection = (data) => {
  console.log('addItemToCommonCollection - running - ', data) 
  setAddingData(data);
  setEditingData(data);
  addItemNoRef(data, props.commonCollection);
  setTrigger(!trigger);  
                                         console.log(1, data, props.commonCollection);
};
//////// 5. Oчикуємо отримання оновденних данних Загал.кол. 
/////// та записуємо commonID в новий документ
useEffect(() => {
  if(controllItemValue !== '') { 
   if(addingData !== {}) {   
    if(controllItemValue === addingData.valueShiftEndDate) {
        
      setCommonID(commonSpendingsList[0].id);
      let data = {
        ...addingData,
        commonItemID : commonSpendingsList[0].id
      }; 
/////// 6. Додамо новий запис в інд. коллекцію     
       addItemNoRef(data, collection);
       
       editItemNoRef(carRunData, collection, carID); /// Оновлю       
      
       setAddingData({}); // Обнуляє переменну AddingData. Запобігає спрацьовиванню строки 50.
       //dataAdded();
                                                  console.log(2, data, collection, carID);
//Видаляє дубль запис
       //delItemNoRef(props.collection, props.docID);
      // goBackToPrevPage();      
    }
   }   
  } 
  // eslint-disable-next-line
 },[controllItemValue, addingData]);
 /// 7. Перезапишим valueShiftEndDate в обидвох колекціях

//const { RentPayment } = useContext(RidesContext);
const { incoms } = useV3Firestore(collection, 1);
                                                    console.log(3,  incoms);
//console.log(1, incoms, props.noData);
useEffect(() => {
  if(props.noData) {
    return
  } else if(incoms.length > 0) {
    //console.log(10, incoms);
      if(editingData.valueShiftEndDate === incoms[0].valueShiftEndDate) {
          let dataToEdit = {
          valueShiftEndDate: +dateNum,
          indCollection: collection,
          indID: incoms[0].id
          };
        // console.log(2, dataToEdit, );
          editItemNoRef(dataToEdit, props.commonCollection, incoms[0].commonItemID);
          editItemWithTrigers(dataToEdit, collection, incoms[0].id, setAddingItem, setDoneAdding, setError);
      } 
  }
// eslint-disable-next-line
}, [incoms, editingData]);
///////////////////////////////////////////////////////////////////////
//// 8. Трігери
const checkForError = () => {
  if (addingItem && !doneAdding) {
    setError(true);
    setAddingItem(false);
    setDoneAdding(false);
    setTimeout(() => setError(false), 4000 );
    return;
  } else if (doneAdding) {
    setError(false);
    return;
  }
};
useEffect(() => {
  if (!error && doneAdding) {
       dataAdded();      
  } if (error) {
    setTimeout(() => setError(false), 4000 ); 
    return; 
  } if (addingItem) {
    setTimeout(() => checkForError(), 30000 );
    return;
  }
// eslint-disable-next-line
}, [error, doneAdding, addingItem]);

//////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////



/////////////////////////////////////// Проверка заполнения формы
const [typeEmpty, setTypeEmpty] = useState(false);
//const [timeEmpty, setTimeEmpty] = useState(false);
const [runEmpty, setRunEmpty] = useState(false);
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
////////////////////////////////////////////////////////////////////////
const [showUrl, setShowUrl] = useState('');
const [showModalForm, setShowModalForm] = useState(false);

///////////////////////////// Объявляем переменные
const [spendType, setSpendType] = useState(props.type || '');
const [sum, setSum] = useState('');
//const [enteredTime, setEnteredTime] = useState('');
const [enteredRun, setEnteredRun] = useState('');
const [note, setNote] = useState('');
const [image, setImage] = useState('');

////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
  //  setEnteredTime('');
    setSum('');
    setNote('');
    setEnteredRun('');
    setImage('');
    setShowModalForm(false);
    setNotice(false);
   // setTimeEmpty(false);
    setRunEmpty(false);
    setDateEmpty(false);
    setSumEmpty(false);
    setShowUrl(false);
  };
///////////////////////////////// Объявляем и получаем данные с формы
  
  const chooseSpendType = (event) => {
    setSpendType(event.target.value);
    setSpendTypeDisplay(event.target.value);
    setTypeEmpty(false);
    setNotice(false);
  };
  //////////////////////////////////////// Преобразуем spendType для отображения в окне ввода
  // eslint-disable-next-line
  const [spendTypeDisplay, setSpendTypeDisplay] = useState('');
  //////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState('');  
  const [sortDate, setSortDate] = useState('');
  const [description, setDescription] = useState('');
 

  //const [displayTime, setDisplayTime] = useState('');

  // const chooseEnteredTime = (event) => {
  //   setDisplayTime(event.target.value);

  //   let hour = event.target.value.slice(0, 2);
  //   let minute = event.target.value.slice(3, 5);
  //   let hourMinute = `${hour}${minute}`;
  //   setEnteredTime(hourMinute);
   
  //   setTimeEmpty(false); 
  //   setNotice(false);     
  // };  
  const chooseEnteredRun = (event) => {
    setEnteredRun(event.target.value);
    setRunEmpty(false); 
    setNotice(false);     
  };   

  const sort = Number(`${sortDate}`);
  //console.log(sort);
/////////////////////////////////////////////////////////////
/////////////////////////// Продолжаем собирать и устанавливать данные формы


const [noShowRun, setNoShowRun] = useState(false);
useEffect(() => {
    if(props.division === 'parts') {
        setNoShowRun(true);
        setEnteredRun(0);
    }
}, [props.division]);

const chooseSum = (event) => {
  setSum(event.target.value); 
  setSumEmpty(false); 
  setNotice(false);    
};   
///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false);
 const dataAdded = () => {
  setAlertSuccess(true);
  setTimeout(() => setAlertSuccess(false), 2000 );
  setTimeout(() => setShowModalForm(false), 2300 );
  setTimeout(() => setDoneAdding(false), 2500 ); 
 // setEnteredTime('');
  setSum('');
  setNote('');
  setImage('');
  setNotice(false);
 // setTimeEmpty(false);
  setDateEmpty(false);
  setSumEmpty(false);
  setShowUrl(false);  
};


// const addItem = (data) => {
//   addItemWithTrigers(data, collection, setAddingItem, setDoneAdding, setError);
// };

 ////////// Проверим заполненность формы 
  const checkForm = () => { 
                        console.log('checkForm - running')     
      if (spendType === '') {
        setTypeEmpty(true); 
        setNotice(true);
      } if (sortDate === '') {
        setDateEmpty(true);
        setNotice(true);
      } 
      // if (enteredTime === '') {
      //   setTimeEmpty(true);
      //   setNotice(true);
      // } 
      if (enteredRun === '') {
        setRunEmpty(true);
        setNotice(true);
      } if (sum === '') {
      setSumEmpty(true);
      setNotice(true);
    } if (!notice) {
      addNewSpend();
    } else {return;}
  };
function addNewSpend () {
 
  ///////////////////////////// Формируем данные поездки
  const data = {
    division: props.division,     
    type: spendType,
    sum: +sum,        
    //time: enteredTime,
    run: enteredRun,
    dateDay: dateDay, 
    dateMonth: dateMonth, 
    dateYear: dateYear,          
    image: image,
    userName: userName,
    note: note,
    car: car,
    sort: sort, 
    description: description 
  };        
  /////////////////////////// Отправлем форму
console.log(data);
  if(props.privat) {
    let dataToEdit = {
      ...data,
      valueShiftEndDate: +dateNum,      
      };
      addItemNoRef(dataToEdit, collection);
      dataAdded()
  } else {
    let dataToEdit = {
      ...data,
      valueShiftEndDate: 2000000000000,      
      };
     setAddingData(dataToEdit);
     addItemToCommonCollection(dataToEdit);
  }
  
  //addItem(data);  
  
  

  }
const fileType = (data) => {
 console.log(data);
}; 

    return (
        <>
        <AddBtn 
         action={() => setShowModalForm(true)}
        />
        <Modal
          //className="bg-dark"
          show={showModalForm}
          onHide={hideModal} 
          size="lg"
          >
          <ModalHeader 
            title={props.header}
           />
           <Modal.Body>
             <Container className={c.modal__form}>
              {alertSuccess && 
                <AlertOnAction 
                    header={'Запис додано'}
                    />
              }
                   
             {!alertSuccess && error ? (
                 <AlertOnAction 
                   variant={'danger'}
                   header={`Збій додавання даних. Перевірте з'єднання`}
                  />                 
               ) : (
           <>
             {!alertSuccess && addingItem ? (
                <AlertOnAction 
                  variant={'info'}
                  header={'Додаю запис в БД'}
                />
                    ) : (
                      <>

               <AlertDangerRound 
                alertHeader={'Выберите раздел'}
                triger={typeEmpty}
                /> 
                <SelectType 
                 type={spendTypeDisplay}
                 onSelect={chooseSpendType}
                />  
                                 
                    <ModalFormline header={'Описание'}>                          
                      <FormControl value={note} onChange={(event) => setNote(event.target.value)} type="text" />                       
                    </ModalFormline> 

                 <DatePicker 
                   header={'Дата'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   setSortDateNum={setSortDate}
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату'}
                   dateEmpty={dateEmpty}
                   dateNum={setDateNum}
                   />
                   
               {/* <AlertDangerRound 
                alertHeader={'Оберить час'}
                triger={timeEmpty}
                />
                 <ModalFormline header={'Час'}>                     
                      <FormControl value={displayTime} onChange={chooseEnteredTime} type="time"/>                    
                 </ModalFormline> */}
                  {!noShowRun && 
                  <>                 
                    <AlertDangerRound
                    alertHeader={'Внесіть пробіг по одометру'}
                    triger={runEmpty}
                    /> 
                    <ModalFormline header={'Пробіг АВТО'}>                     
                        <FormControl value={enteredRun} onChange={chooseEnteredRun} type="number"/>                    
                    </ModalFormline> 
                  </>}

                  
                 <AlertDangerRound
                  alertHeader={'Внесіть вартість витрати'}
                  triger={sumEmpty}
                  /> 
                  <ModalInputGroup 
                     header={'Витрачина сума'}
                     value={sum} onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    />

                   <ModalFormline header={'Примітка'}>                          
                         <FormControl value={description} onChange={(event) => setDescription(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 
                   
           
                   <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                   
                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будьласка, заповніть потрібні дані'}
                     />
                    </>)}                     
                  </>)} 
             </Container>
           </Modal.Body>
           <FooterButtons 
            onCancel={hideModal}
            onAccept={checkForm}
            disabled={error || addingItem || loadingImg}
           />   
         </Modal>
        </>
    )
}

export default AddNewTO
