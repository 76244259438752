import React, { useEffect, useState, useContext } from 'react';
import { ContractContext } from '../Context/ContractContext';
import c from '../Components/Screen.module.css';
import PageTitle from '../Components/PageTitle';
import NavBarInit from '../Components/NavBarInit';
import useGetCommonItemID from '../Hooks/useGetCommonItems';
import useGetCommonSpendings from '../Hooks/useGetCommonSpendings';
import Incoms from './Incoms';
import '../Components/tabs.css';
import {stateA, stateB, stateAres, stateBres, yearMonthsObj} from '../Components/Constants';

// Sorting Block
import useFindCarCAR from '../Hooks/useFindCarCAR';
import useFindMonth from '../Hooks/useFindMonth';
import useFindType from '../Hooks/useFindType';
import { calculateData, excludeCommons, excludeLosses } from '../Functions';
import Spendings from './Spendings';

import useFindCarSpend from '../Hooks/useFindCarSpend';
import useFindMonthSpend from '../Hooks/useFindMonthSpend';
import useFindTypeSpend from '../Hooks/useFindTypeSpend';
import Balance from './Balance';
import Amortization from './Amortization';
///////////////////////


const Financial = () => {

const commonIncomsCollection = `commonIncoms`;
const commonSpendingsCollection = `commonSpendings`;
  

////////////// БЛОК КЕРУВАННЯ ВКЛАДКАМИ ////////////////////////////////
const [toggleState, setToggleState] = useState(+sessionStorage.getItem('FinanceTab') || 1);
const toggleTab = (index) => {
     setToggleState(index);
}; 
const chooseTab =(tab) => {
     toggleTab(tab);
     sessionStorage.setItem('FinanceTab', tab); 
};
////////////// КІНЕЦЬ БЛОКУ КЕРУВАННЯ ВКЛАДКАМИ ////////////////////////////////
 
/////////////// БЛОК КЕРУВАННЯ НАЗВАМИ ВКЛАДКИ  - HELMET /////////////////////
const [helmetDescription, setHelmetDescription] = useState('');
useEffect(() => {
 if(toggleState === 1) {
  setHelmetDescription('Прибутки');
 } else if(toggleState === 2) {
  setHelmetDescription('Видатки');
 } else if(toggleState === 3) {
  setHelmetDescription('Баланс');
 } else if(toggleState === 4) {
  setHelmetDescription('Амортізація');
 } 
}, [toggleState]);
/////////////// КІНЕЦЬ БЛОКУ КЕРУВАННЯ НАЗВАМИ ВКЛАДКИ  - HELMET //////////////
const combLim = 500;
const [incomsLimit, setIncomsLimit] = useState(combLim);
const [SpendingLimit, setSpendingLim] = useState(combLim);

/////////////////////////////////////////////////////////////////////////////
// INCOMS BLOCK ////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
const { commonIncoms, setCommonIncoms } = useContext(ContractContext);
// eslint-disable-next-line 
const [trigger, setTrigger] = useState(false);
const {commonIncomsList, loadingDocs} = useGetCommonItemID(commonIncomsCollection, trigger, incomsLimit);
useEffect(() => {
  if(commonIncomsList.length > 0) {   
    setCommonIncoms(commonIncomsList);    
  } 
  // eslint-disable-next-line
 },[commonIncomsList]);
////////////////////////////////////////////////


// Sorting Block
const [lookForMonth, setLookForMonth] = useState('');
const [lookForCar, setLookForCar] = useState('');
const [lookForType, setLookForType] = useState('');

const [carData, setCarData] = useState([]);
const [dataType, setDataType] = useState([]);

const {dataCar, carArr, monthArr} = useFindCarCAR(commonIncoms, lookForCar, lookForMonth, lookForType);

useEffect(() => {
 setCarData(carArr);     
}, [carArr]);


const [conditionalArr, setConditionalArr] = useState([]);
const [noCommons, setNoCommons] = useState(false);
const { carMonthData } = useFindMonth(dataCar, lookForMonth, lookForType, lookForCar);
const { typeArr, carTypeData } = useFindType(commonIncoms, carMonthData, lookForType);


//////// carTypeData - Подставляется в список полученных данных для этерации в таблице
useEffect(() => {
setDataType(typeArr);     
}, [typeArr]);
///////////////////////// Виключає прибутки АВТОПАРКУ
useEffect(() => {
  if(!noCommons) {
    setConditionalArr(carTypeData);
  } else if(noCommons) {
    let data = excludeCommons(carTypeData);
    setConditionalArr(data);
  } 
}, [carTypeData, noCommons]);
////////////////////////////////////////////////////


////////////////// Підрахунок кількості та суми прибутків
const [numberOfRecordsFound, setNumberOfRecordsFound] = useState('');
const [incomsSum, setIncomsSum] = useState(0);

useEffect(() => {
 if (conditionalArr) {
   setNumberOfRecordsFound(conditionalArr.length);  
//////// Підрахуємо суму прибутків
let sum = calculateData(conditionalArr);
setIncomsSum(sum);
///////////////////////
 } else {
    if (commonIncoms && commonIncoms.length > 0 ) {
   setNumberOfRecordsFound(commonIncoms.length); 
  } else {
   setNumberOfRecordsFound(0);   
  }    
 } 
 // eslint-disable-next-line    
}, [commonIncoms, commonIncomsList, carTypeData, conditionalArr]);
/////////////////////////////
//  END OF INCOMS BLOCK ////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////



///// SPENDINGS BLOCK ///////////////////////////////////////
const { commonSpendings, setCommonSpendings } = useContext(ContractContext);
// eslint-disable-next-line
const [spendingsTrigger, setSpendingsTrigger] = useState(false);
const { commonSpendingsList, loadingSpendingDocs } = useGetCommonSpendings(commonSpendingsCollection, spendingsTrigger, SpendingLimit);
useEffect(() => {
  if(commonSpendingsList.length > 0) {   
    setCommonSpendings(commonSpendingsList);       
  } 
  // eslint-disable-next-line
 },[commonSpendingsList]);
////////////////////////////////////////////////


// Sorting Block
const [lookForMonthSpend, setLookForMonthSpend] = useState('');
const [lookForCarSpend, setLookForCarSpend] = useState('');
const [lookForTypeSpend, setLookForTypeSpend] = useState('');

const [carDataSpend, setCarDataSpend] = useState([]);
const [dataTypeSpend, setDataTypeSpend] = useState([]);

const {dataCarSpend, carArrSpend, monthArrSpend} = useFindCarSpend(commonSpendings, lookForCarSpend, lookForMonthSpend, lookForTypeSpend);

useEffect(() => {
 setCarDataSpend(carArrSpend);     
}, [carArrSpend]);


const [conditionalArrSpend, setConditionalArrSpend] = useState([]); /// Масів Видатків
const [noLosses, setNoLosses] = useState(false);
const [noCommonsSpend, setNoCommonsSpend] = useState(false);
const { carMonthDataSpend } = useFindMonthSpend(dataCarSpend, lookForMonthSpend, lookForTypeSpend, lookForCarSpend);
const { typeArrSpend, carTypeDataSpend } = useFindTypeSpend(commonSpendings, carMonthDataSpend, lookForTypeSpend);


//////// carTypeData - Подставляется в список полученных данных для этерации в таблице
useEffect(() => {
  setDataTypeSpend(typeArrSpend);     
}, [typeArrSpend]);
///////////////////////// Виключає прибутки АВТОПАРКУ та LOSSES
useEffect(() => {
  if(!noCommonsSpend && !noLosses) {
    setConditionalArrSpend(carTypeDataSpend);
  } else if(noCommonsSpend && !noLosses) {
    let data = excludeCommons(carTypeDataSpend);
    setConditionalArrSpend(data);
  } else if(noLosses && !noCommonsSpend) {
    let data = excludeLosses(carTypeDataSpend);
    setConditionalArrSpend(data);
  } else if(noCommonsSpend && noLosses) {
    let data = excludeCommons(carTypeDataSpend);
    let data2 = excludeLosses(data);
    setConditionalArrSpend(data2);
  } 
}, [carTypeDataSpend, noCommonsSpend, noLosses]);
////////////////////////////////////////////////////
/////////////////// Виключає 


////////////////// Підрахунок кількості та суми витрат 
 const [numOfSpendingsFound, setNumOfSpendingsFound] = useState('');
 const [spendingsSum, setSpendingsSum] = useState(0);

 useEffect(() => {
  if (conditionalArrSpend) {
    setNumOfSpendingsFound(conditionalArrSpend.length); 
 //////// Підрахуємо суму витрат
 let sum = calculateData(conditionalArrSpend);
 setSpendingsSum(sum);
 ///////////////////////
   } 
 else {
     if (commonSpendings && commonSpendings.length > 0 ) {
    setNumOfSpendingsFound(commonSpendings.length);  
   } else {
    setNumOfSpendingsFound(0);
   }    
  }     
 }, [conditionalArrSpend, carTypeData, commonSpendings]);
////////////////////////////////////////////////////////////////
const currentYear = new Date().getFullYear();
const currentMonth = new Date().toLocaleString('uk-UA', {month: '2-digit'});
const [years, setYears] = useState([]);
 
 
     useEffect(() => {
         if(years.length <= currentYear - 2021) {
             let arr = [];
            for (let year = 2021; year < currentYear; year++) {
           arr.push({year, ...yearMonthsObj});
           } 
          arr.push({year: currentYear, ...yearMonthsObj});  
           setYears(arr);
         }
         console.log(years);
   // eslint-disable-next-line
     }, [years]); 
///////////////////////////////////////////////////////////////

//console.log(commonSpendings)

  return (
    <div className={`${c.container} ${c.home}`}>
    <PageTitle 
     title={`S&N | ${helmetDescription}`}        
     />
    <NavBarInit />
    

    <div className="tabbar_container">         
            <div className="tabbar">
             <div className="tabbar__left">
                <span className={toggleState === 1 ? stateA : stateB}                  
                    onClick={() => chooseTab(1)}
                  >
                    ПРИБУТКИ
                </span>

                <span className={toggleState === 2 ? stateA : stateB}
                  onClick={() => chooseTab(2)}
                  >
                    ВИДАТКИ
                 </span> 
                 
                 <span className={toggleState === 3 ? stateA : stateB}
                  onClick={() => chooseTab(3)}
                  >
                     БАЛАНС
                 </span> 

                 <span className={toggleState === 4 ? stateA : stateB}
                  onClick={() => chooseTab(4)}
                  >
                     АМОРТІЗАЦІЯ
                 </span> 
                
                 
                         
            </div> 
          </div>
          <div className="tab_container_job_request">
                <nav className="content-tabs">
                    <div className={toggleState === 1 ? stateAres : stateBres}>
                     <Incoms 
                          data={commonIncoms}
                          loadingDocs={loadingDocs}                          
                          editCollection={commonIncomsCollection}

                          carData={carData}
                          lookForCar={setLookForCar}
                          lookForType={setLookForType}
                          lookForMonth={setLookForMonth}

                          car={lookForCar}
                          month={lookForMonth}
                          setLookForMonth={setLookForMonth}
                          setLookForType={setLookForType}

                          dataDate={monthArr}                        
                          dataType={dataType}
                          carTypeData={conditionalArr}

                          numberOfRecordsFound={numberOfRecordsFound}
                          listSum={incomsSum}
                          monthArr={monthArr} 
                          collection={commonIncomsCollection}

                          noCommons={noCommons} 
                          setNoCommons={setNoCommons}

                          incomsLimit={incomsLimit} 
                          setIncomsLimit={setIncomsLimit}
                      />         
                    </div>

                    <div className={toggleState === 2 ? stateAres : stateBres}>  
                      <Spendings 
                        data={commonSpendings}
                        loading={loadingSpendingDocs}

                        carData={carDataSpend}
                        lookForCar={setLookForCarSpend}
                        lookForType={setLookForTypeSpend}
                        lookForMonth={setLookForMonthSpend}

                        car={lookForCarSpend}
                        month={lookForMonthSpend}
                        setLookForMonth={setLookForMonthSpend}
                        setLookForType={setLookForTypeSpend}

                        dataDate={monthArrSpend}                        
                        dataType={dataTypeSpend}
                        carTypeData={conditionalArrSpend}

                       
                        numberOfRecordsFound={numOfSpendingsFound} 
                        listSum={spendingsSum}
                        monthArr={monthArrSpend}
                        collection={commonSpendingsCollection}
                        
                        noCommons={noCommonsSpend} 
                        setNoCommons={setNoCommonsSpend}
                        noLosses={noLosses} 
                        setNoLosses={setNoLosses}

                        incomsLimit={SpendingLimit} 
                        setIncomsLimit={setSpendingLim}

                        sum={spendingsSum}
                      />                        
                        
                    </div> 
                  
                     <div className={toggleState === 3 ? stateAres : stateBres}>                     
                       {/* <Balance 
                         monthArrSpend={monthArrSpend}
                         monthArrInc={monthArr}

                         dataIncoms={commonIncoms}
                         dataSpendings={commonSpendings}

                         setIncomsLimit={setIncomsLimit}
                         setSpendingLim={setSpendingLim}

                         currentYear={currentYear}
                         currentMonth={currentMonth}
                         years={years}
                       /> */}
                    </div>  
                    <div className={toggleState === 4 ? stateAres : stateBres}>                     
                      {/* <Amortization 
                        currentYear={currentYear}
                        currentMonth={currentMonth}
                        years={years}
                      /> */}
                    </div>  

                    
                 </nav>
             </div>
        </div>
    
</div>
  )
}

export default Financial