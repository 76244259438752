import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { TrashIcon } from '../Components/Icons';
import { useAuth } from "../Context/AuthContext";
import c from './Screen.module.css';
import FooterButtons from './FooterButtons';
import { delItemNoRef } from '../Functions';

const DeleteConfirmForm = ( props ) => {

  const { isAdmin } = useAuth();
////////////// Контроль отображения Модального окна
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
///////////////////////////////////////////////////
const history = useHistory();
// Используется для перенаправления на нужную страницу сайта после удаленя элемента
//const car = sessionStorage.getItem('car');
const checkUser = () => {
  isAdmin && deliteRideAndCloseModal();
  !isAdmin &&  setShow(false);  
};

const deliteRideAndCloseModal = () => {
  delItemNoRef(props.collection, props.docID); 
  if (props.secondCollection && props.secondID) {
    //console.log(props.secondCollection, props.secondID);
    delItemNoRef(props.secondCollection, props.secondID); 
  }   
       history.push(`${props.goTo}`);  
        setShow(false);  
};

    return (
        <>
         {props.icon ? (
           <span onClick={handleShow} >{TrashIcon(props.iconSize && props.iconSize)}</span> 
           ) : (
             <Button variant={props.style ? (props.style) : ("danger")} onClick={handleShow} >
              {!props.short && <span className={c.text_invis}>Ooo</span>} 
                Видалити {props.idWord}
              {!props.short && <span className={c.text_invis}>Ooo</span>}
             </Button> 
         )}
            

     <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Підтвердіть видалення обранного {props.idWord2}</Modal.Title>
        </Modal.Header>
        
        <FooterButtons 
          onCancel={handleClose}
          onAccept={checkUser}
          variant1={"outline-success"} 
          variant2={"danger"} 
          text2={'Так, видалити'}
          disabled={false}
        />       
      </Modal>
            
        </>
    )
}

export default DeleteConfirmForm
