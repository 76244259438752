import { useState, useEffect } from 'react';
import firebase from "../firebase";

 const useGetCommonItems = (collection, trigger, limit) => {
  
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [commonIncomsList, setCommonIncomsList] = useState([]);
  
  useEffect(() => {
     setLoadingDocs(true);
    let ubsubscribeShifts = firebase.firestore().collection(collection)   
    .orderBy('valueShiftEndDate', 'desc')
    .limit(limit)    
    .onSnapshot((snap) => {
         const data = snap.docs.map( doc => {
           return {
              id : doc.id,
              ...doc.data()
           };
         });
         setCommonIncomsList(data);
         setLoadingDocs(false);
    });
    return () => { 
     ubsubscribeShifts();
     };

  }, [collection, trigger, limit]);
  return { 
    commonIncomsList,
    loadingDocs,
   // Data,
    
  };
};
export default useGetCommonItems; 

